import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postNewNotice, updateNotice, getNotice } from '../../Redux/Actions/Index';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Button, Upload, DatePicker } from 'antd';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom"; // Para obtener el ID de la noticia
import "./AdminDashboard.css";

function FormularioCrearNoticia() {
    const dispatch = useDispatch();
    const { noticeId } = useParams(); // Obtener el ID de la noticia desde los parámetros de la URL
    const notice = useSelector(state => state.noticeDetail); // Obtener la noticia del estado global

    const [fileList, setFileList] = useState([]);
    const [inputNoticia, setInputNoticia] = useState({
        title: "",
        description: "",
        date: null,
    });
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (noticeId) {
            setIsEditing(true);
            dispatch(getNotice(noticeId)); // Obtener la noticia si estamos en modo edición
        }
    }, [noticeId, dispatch]);

    useEffect(() => {
        if (isEditing && notice) {
            setInputNoticia({
                title: notice.title,
                description: notice.description,
                date: notice.date,
            });
        }
    }, [isEditing, notice]);

    const handleInputChange = (e) => {
        setInputNoticia({
            ...inputNoticia,
            [e.target.name]: e.target.value,
        });
    };

    const handleDateChange = (date, dateString) => {
        setInputNoticia({
            ...inputNoticia,
            date: dateString,
        });
    };

    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const validateFileType = (file) => {
        const allowedTypes = ["image/jpeg", "image/png", "image/webp", "image/jpg", "image/jfif"];
        if (!allowedTypes.includes(file.type)) {
            Swal.fire({
                icon: 'error',
                title: 'Archivo no válido',
                text: 'Solo se permiten archivos con extensiones jpg, jpeg, png, webp o jfif.',
            });
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('title', inputNoticia.title);
        formData.append('description', inputNoticia.description);
        formData.append('date', inputNoticia.date);
        if (fileList.length > 0) {
            formData.append('imageUrl', fileList[0].originFileObj);
        }

        try {
            if (isEditing) {
                await dispatch(updateNotice(noticeId, formData));
                Swal.fire('Noticia actualizada', 'La noticia ha sido actualizada exitosamente', 'success');
            } else {
                await dispatch(postNewNotice(formData));
                Swal.fire('Noticia creada', 'La noticia ha sido creada exitosamente', 'success');
            }
            // Limpiar el formulario
            setInputNoticia({
                title: "",
                description: "",
                date: null,
            });
            setFileList([]);
        } catch (error) {
            console.error("Error posting new notice:", error);
        }
    };

    return (
        <div className="form-ant">
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                style={{ maxWidth: 600 }}
                onFinish={handleSubmit}
            >
                <h1>{isEditing ? 'Editar Noticia' : 'Subir Noticia'}</h1>

                <Form.Item label="Titulo">
                    <Input
                        onChange={handleInputChange}
                        name="title"
                        placeholder="Escriba el titulo"
                        value={inputNoticia.title}
                    />
                </Form.Item>

                <Form.Item label="Imagen">
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onChange={handleFileChange}
                        beforeUpload={validateFileType}
                    >
                        {fileList.length < 1 && (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                        )}
                    </Upload>
                </Form.Item>

                <Form.Item label="Descripcion">
                    <Input.TextArea
                        rows={4}
                        onChange={handleInputChange}
                        name="description"
                        placeholder="Descripcion"
                        value={inputNoticia.description}
                    />
                </Form.Item>

                <Form.Item label="Fecha">
                    <DatePicker
                        format="DD/MM/YYYY"
                        onChange={handleDateChange}
                        value={inputNoticia.date ? moment(inputNoticia.date, "DD/MM/YYYY") : null}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {isEditing ? 'Actualizar' : 'Guardar'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export { FormularioCrearNoticia };