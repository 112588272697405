import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getNotice } from '../../Redux/Actions/Index';
import './Noticia.css';

const Noticia = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    
    // Obtén el artículo del estado global
    const article = useSelector((state) => state.noticeDetail);
    const isAuthenticated = useSelector((state) => state.isAuthenticated); // Verificar si el usuario está autenticado

    useEffect(() => {
        dispatch(getNotice(id));
    }, [dispatch, id]);

    if (!article) {
        return <p>Cargando artículo...</p>; // Puedes mostrar un spinner o un mensaje de carga aquí
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={8}>
                    <h2>{article.title}</h2>
                    <img src={article.imageUrl} alt={article.title} className="img-fluid mb-3" />
                    <p>{article.description}</p>
                    <p><small>{article.date}</small></p>
                    {isAuthenticated && ( // Mostrar el botón solo si el usuario está autenticado como admin
                        <Link to={`/editar-noticia/${id}`}>
                            <Button variant="primary">Editar Noticia</Button>
                        </Link>
                    )}
                    <Button variant="dark" href="/Diario">Volver</Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Noticia;